import { Column } from "@enymo/react-layout";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { PostSettingsKeys, PostSettings as PostSettingsType, useCampaigns, useUsers } from "../resources";

import plans from "@/json/plans.json";
import postSettingsJson from "@/json/post-settings.json";
import { useController } from "react-hook-form";
import { CheckboxSmall, RadioButton, RadioGroup } from "../glidespec";
import Foldable from "./Foldable";
import Tooltip, { TooltipParent } from "./Tooltip";

const postSettings = postSettingsJson as {
    group: "facebook" | "tiktok" | "youtube",
    settings: ({
        oneOf?: string,
        name: string,
        requiresFeatures?: ("stories")[],
    } & ({
        type: "boolean",
        defaultValue: boolean,
    } | {
        type: "choice",
        defaultValue: string,
        choices: string[],
    }))[]
}[]

export default function PostSettings({
    settings,
    onSettingsChange,
    onRepostChange,
    hideTitle = false,
    shouldRepost
}: {
    settings?: PostSettingsType,
    shouldRepost?: boolean
    onSettingsChange: (group: keyof PostSettingsType, name: PostSettingsKeys, value: boolean | string) => void,
    onRepostChange: (value: boolean) => void,
    hideTitle?: boolean
}) {
    const { t } = useTranslation();
    const { campaignId } = useParams();
    const [campaign] = useCampaigns({ id: Number(campaignId) });
    const [campaignOwner] = useUsers({ id: campaign?.owner.id ?? null, autoRefresh: !!campaign });

    const [oneOfs, oneOfCounts] = useMemo(() => {
        const oneOfCounts: { [key: string]: number } = {};
        const oneOfs: { [key: string]: string } = {};

        for (const { group, settings: settingsInfo } of postSettings) {
            for (const { oneOf, name, defaultValue } of settingsInfo) {
                if (oneOf) {
                    oneOfCounts[oneOf] ??= 0;
                    if((settings?.[group] as any)?.[name] ?? defaultValue) 
                        oneOfCounts[oneOf]++;
                    oneOfs[`${group}.${name}`] = oneOf;
                }
            }
        }

        return [oneOfs, oneOfCounts];
    }, [settings]);

    const handleSettingsChange = (group: keyof PostSettingsType, name: PostSettingsKeys) => (value: string | boolean) => {
        if (!value && oneOfs[`${group}.${name}`] && oneOfCounts[oneOfs[`${group}.${name}`]] < 2) {
            return;
        }
        onSettingsChange(group, name, value);
    }

    return (
        <Foldable title={hideTitle ? undefined : t("settings")}>
            <Column className="settings" gap="16px">
                {settings && postSettings.map(({ group, settings: availableSettings }) => (
                    <Column key={group} gap="12px">
                        <span className="category-title">{t(`posts.create.settings.${group}`)}</span>
                        <Column gap="5px">
                            {availableSettings.map((setting) => {
                                switch (setting.type) {
                                    case "choice":
                                        {
                                            const { choices, defaultValue, name, requiresFeatures } = setting;
                                            const disabled = !campaignOwner || requiresFeatures?.some(feature => !plans[campaignOwner.subscription_tier][feature]);
                                            const radioGroup = (
                                                <RadioGroup
                                                    key={name}
                                                    value={((settings?.[group] as any)?.[name] as string) ?? defaultValue}
                                                    onChange={handleSettingsChange(group, name as PostSettingsKeys)}
                                                >
                                                    {choices.map((choice) => {
                                                        const disabled = !campaignOwner || requiresFeatures?.some(feature => !plans[campaignOwner.subscription_tier][feature]);
                                                        return (
                                                            <RadioButton
                                                                key={choice}
                                                                value={choice}
                                                                disabled={disabled}
                                                            >
                                                                {t(`posts.create.settings.${group}.${name}.${choice}`)}
                                                            </RadioButton>
                                                        )
                                                    })}
                                                </RadioGroup>
                                            )
                                            if (disabled) {
                                                return (
                                                    <TooltipParent key={name} style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "5px",
                                                    }}>
                                                        {radioGroup}
                                                        <Tooltip>{t("posts.create.settings.settingDisabled")}</Tooltip>
                                                    </TooltipParent>
                                                )
                                            }
                                            return radioGroup;
                                        }
                                    case "boolean":
                                        {
                                            const { name, defaultValue, requiresFeatures } = setting;
                                            const disabled = !campaignOwner || requiresFeatures?.some(feature => !plans[campaignOwner.subscription_tier][feature]);
                                            const checkbox = (
                                                <CheckboxSmall
                                                    key={name}
                                                    checked={((settings[group] as any)?.[name] as boolean) ?? defaultValue}
                                                    onChange={handleSettingsChange(group, name as PostSettingsKeys)}
                                                    disabled={disabled}
                                                >
                                                    {t(`posts.create.settings.${group}.${name}`)}
                                                </CheckboxSmall>
                                            )
                                            if (disabled) {
                                                return (
                                                    <TooltipParent key={name}>
                                                        {checkbox}
                                                        <Tooltip>{t("posts.create.settings.settingDisabled")}</Tooltip>
                                                    </TooltipParent>
                                                )
                                            }
            
                                            return checkbox;
                                        }
                                }
                                return null;
                            })}
                        </Column>
                    </Column>
                ))}
                <Column gap="12px">
                    <span className="category-title">{t("posts.create.settings.reposting")}</span>
                    {shouldRepost !== undefined && <CheckboxSmall checked={shouldRepost} onChange={onRepostChange}>
                        {t("posts.create.settings.reposting.repost")}
                    </CheckboxSmall>}
                </Column>
            </Column>
        </Foldable>
    )
}

export function PostSettingsController({name, hideTitle = false}: {
    name: string,
    hideTitle?: boolean
}) {
    const {field: {value: settings, onChange: onChangeSettings}} = useController({name: `${name}.settings`, defaultValue: {}});
    const {field: {value: shouldRepost, onChange: onChangeShouldRepost}} = useController({name: `${name}.shouldRepost`, defaultValue: false});

    const handleChangeSettings = (group: keyof PostSettingsType, name: PostSettingsKeys, value: boolean | string) => {
        onChangeSettings({
            ...settings,
            [group]: {
                ...settings[group],
                [name]: value
            }
        })
    }

    return (
        <PostSettings
            settings={settings}
            shouldRepost={shouldRepost}
            onRepostChange={onChangeShouldRepost}
            onSettingsChange={handleChangeSettings}
            hideTitle={hideTitle}
        />
    )
}