import "@/css/components/weekday-select.scss";
import { Column } from "@enymo/react-layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { weekdays } from "../common";
import { CheckboxList, WeekdaySelectItem } from "../glidespec";
import { FormInputProps } from "../types";

interface WeekdaySelectProps extends FormInputProps<typeof weekdays[number][]> {
    label?: string,
    multiple?: boolean
}

export default function WeekDaySelect({
    label,
    multiple = false,
    ...props
}: WeekdaySelectProps) {
    const {t} = useTranslation();

    return (
        <Column gap="8px">
            {label && <label className="input-label">{label}</label>}
            <CheckboxList className="weekday-select-grid" handlesError {...props}>
                {weekdays.map(weekday => (
                    <WeekdaySelectItem key={weekday} value={weekday}>{t(`weekdays.${weekday}.short`)}</WeekdaySelectItem>
                ))}
            </CheckboxList>
        </Column>
    )
}