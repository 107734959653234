import { Weekday } from "@/js/common";
import MonthDaySelectSingle from "@/js/Components/MonthDaySelectSingle";
import { PostSettingsController } from "@/js/Components/PostSettings";
import { SwitcherInput } from "@/js/Components/SwitcherInput";
import WeekDaySelectSingle from "@/js/Components/WeekdaySelectSingle";
import { Button, Checkbox, Input, SlideSwitch, SlideSwitchItem } from "@/js/glidespec";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useApp } from "@/js/Providers/AppProvider";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { useUser } from "@/js/Providers/UserProvider";
import { PostSettings, useCampaigns } from "@/js/resources";
import TrashIcon from "@/svg/trash-regular.svg?react";
import { Form, setFormValues, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { requireNotNull } from "@enymo/ts-nullsafe";
import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router";
import route from "ziggy-js";

interface Submit {
    url: string,
    auto: boolean,
    withWeekdays: boolean,
    withSettings: boolean,
    withTimezone: boolean,
    frequency: "hourly" | "daily" | "weekly" | "monthly" | "yearly",
    date: Date,
    time: string,
    weekday: Weekday,
    dayOfMonth: number | "last",
    hourlyInterval: number,
    times: {
        time: string
    }[],
    post: {
        settings: PostSettings,
        shouldRepost: boolean
    }
}

export default function Importer() {
    const {t} = useTranslation();
    const {user} = useUser();
    const form = useForm<Submit>({
        defaultValues: {
            times: [{
                time: ""
            }]
        }
    });
    const mobile = useBreakpoint(768);
    const {campaignId} = useParams()
    const [campaign ,{update}] = useCampaigns({id: Number(requireNotNull(campaignId))});
    const frequency = form.watch("frequency");
    const {axios} = useApp();
    const toast = useToaster();
    const {fields, append, remove} = useFieldArray({name: "times", control: form.control, rules: {
        minLength: 1,
        maxLength: 4
    }});

    useEffect(() => {
        setFormValues(form, {
            auto: campaign?.import_auto,
            withWeekdays: campaign?.import_with_weekdays,
            withSettings: campaign?.import_with_settings,
            withTimezone: campaign?.import_with_timezone,
            dayOfMonth: campaign?.import_day_of_month,
            frequency: campaign?.import_frequency,
            url: campaign?.import_url ?? undefined,
            weekday: campaign?.import_weekday,
            hourlyInterval: campaign?.import_hourly_interval,
            times: campaign?.import_times.length ? campaign?.import_times.map(time => ({time})) : undefined
        });
        if (campaign) {
            form.setValue("post.shouldRepost", campaign.import_should_repost);
            form.setValue("post.settings", campaign.import_post_settings);
        }
    }, [form, campaign]);

    const handleManualImport = async () => {
        await axios.post(route("campaigns.import", {campaign: campaignId}), {
            import_url: form.getValues("url")
        });
        toast({
            type: "success",
            title: t("campaigns.settings.importer.start.title"),
            text: t("campaigns.settings.importer.start.text"),
            duration: 5000
        })
    }

    const handleSubmit: SubmitHandler<Submit> = async data => {
        await update({
            import_url: data.url,
            import_auto: data.auto,
            import_with_weekdays: data.withWeekdays,
            import_with_settings: data.withSettings,
            import_with_timezone: data.withTimezone,
            import_frequency: data.frequency,
            import_weekday: data.weekday,
            import_day_of_month: data.dayOfMonth,
            import_hourly_interval: data.hourlyInterval,
            import_times: data.times.filter(({time}) => time).map(({time}) => time),
            import_post_settings: data.post.settings,
            import_should_repost: data.post.shouldRepost
        });
        toast({
            type: "success",
            title: t("campaigns.settings.saved"),
            text: t("campaigns.settings.importer.saved")
        })
    }

    if (!user?.importer) {
        return <Navigate to={`/app/campaigns/${campaignId}/settings/general`} replace />
    }

    return (

        <Form form={form} onSubmit={handleSubmit}>
            <Row gap="60px" padding={mobile ? "35px 16px" : "35px 50px"}>
                <Column flex={1} maxWidth="510px" gap="40px">
                    <Column gap="30px" hAlign="left">
                        <Column gap="10px" hAlign="left" selfAlign="stretch">
                            <Row gap="12px" vAlign="bottom" selfAlign="stretch">
                                <Input flex={1} label={t("campaigns.settings.importer.url")} name="url" placeholder={t("campaigns.settings.importer.url.placeholder")} />
                                <Button variant="primary" onClick={handleManualImport}>{t("campaigns.settings.importer.manualImport")}</Button>
                            </Row>
                            <Button variant="link">{t("campaigns.settings.importer.downloadExample")}</Button>
                        </Column>
                        <Row gap="50px" vAlign="bottom">
                            <SwitcherInput
                                name="auto"
                                label={t("campaigns.settings.importer.auto")}
                                activeLabel={t("active")}
                                inactiveLabel={t("inactive")}
                            />
                        </Row>
                        <Row gap="50px">
                            <Checkbox name="withWeekdays">{t("campaigns.settings.importer.withWeekdays")}</Checkbox>
                            <Checkbox name="withSettings">{t("campaigns.settings.importer.withSettings")}</Checkbox>
                            <Checkbox name="withTimezone">{t("campaigns.settings.importer.withTimezone")}</Checkbox>
                        </Row>
                        <SlideSwitch label={t("frequency")} name="frequency">
                            <SlideSwitchItem value="hourly">{t("frequency.hourly")}</SlideSwitchItem>
                            <SlideSwitchItem value="daily">{t("frequency.daily")}</SlideSwitchItem>
                            <SlideSwitchItem value="weekly">{t("frequency.weekly")}</SlideSwitchItem>
                            <SlideSwitchItem value="monthly">{t("frequency.monthly")}</SlideSwitchItem>
                        </SlideSwitch>
                        {frequency === "weekly" && (
                            <WeekDaySelectSingle style={{width: "400px"}} name="weekday" label={t("weekday")} />
                        )}
                        {frequency === "monthly" && (
                            <MonthDaySelectSingle style={{width: "300px"}} name="dayOfMonth" label={t("campaigns.settings.importer.dayOfMonth")} />
                        )}
                        {frequency === "hourly" ? (
                            <Input type="select" name="hourlyInterval" label={t("campaigns.settings.importer.hourlyInterval")} options={{
                                valueAsNumber: true
                            }}>
                                {["6", "8", "12"].map(hours => (
                                    <option key={hours} value={hours}>{t("campaigns.settings.importer.hourlyInterval.hours", {hours})}</option>
                                ))}
                            </Input>
                        ) : (
                            <Column gap="10px">
                                <span className="input-label">{t("campaigns.settings.importer.time")}</span>
                                {fields.map(({id}, index) => (
                                    <Row key={id} gap="12px" vAlign="center">
                                        <Input type="time" name={`times.${index}.time`} options={{
                                            required: true
                                        }} />
                                        {fields.length > 1 && (
                                            <button type="button" onClick={() => remove(index)}>
                                                <TrashIcon width="12px" className="error" />
                                            </button>
                                        )}
                                    </Row>
                                ))}
                                {fields.length < 4 && <Button variant="link" onClick={() => append({time: ""})}>{t("add")}</Button>}
                            </Column>
                        )}
                    </Column>
                    <Row align="end">
                        <Button variant="primary" submit>{t("save")}</Button>
                    </Row>
                </Column>
                <PostSettingsController name="post" hideTitle />
            </Row>
        </Form>
    )
}