import { createRequiredContext } from "@enymo/react-better-context";
import { ResourceProvider } from "@enymo/react-resource-hook";
import { SocketProvider } from "@enymo/react-socket-hook";
import globalAxios, { AxiosError, AxiosInstance } from "axios";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";
import route from "ziggy-js";


const [Provider, useApp] = createRequiredContext<{
    axios: AxiosInstance,
    networkDown: boolean,
    jwt: string | null,
    setJwt: (jwt: string | null) => void
}>("AppProvider must be present in the component tree");

export { useApp };
export default function AppProvider({children}: {
    children: React.ReactNode
}) {
    const {i18n} = useTranslation();
    const [jwt, setJwt] = useState<string | null>(null);
    const [networkDown, setNetworkDown] = useState(false);
    const networkDownRef = useRef(false);
    const limbo = useRef<(() => void)[]>([]);

    const handleSetNetworkDown = useCallback((networkDown: boolean) => {
        setNetworkDown(networkDown);
        networkDownRef.current = networkDown;
        if (!networkDown) {
            for (const release of limbo.current) {
                release();
            }
            limbo.current.length = 0;
        }
    }, [setNetworkDown, networkDownRef, limbo]);

    const axios = useMemo(() => {
        const axios = globalAxios.create({
            headers: {
                "accept-language": i18n.language
            }
        });
        axios.interceptors.request.use(config => {
            if (networkDownRef.current && config.url !== route("placebo")) {
                return new Promise(resolve => {
                    limbo.current.push(() => resolve(config));
                });
            }
            return config;
        });
        axios.interceptors.response.use(null, error => {
            if (error instanceof AxiosError && error.code !== "ERR_CANCELED" && error.response === undefined && error.config !== undefined) {
                handleSetNetworkDown(true);
                return axios.request(error.config);
            }
            return Promise.reject(error);
        });
        return axios;
    }, [handleSetNetworkDown, networkDownRef]);

    const socket = useMemo(() => {
        const socket = io(import.meta.env.VITE_SOCKET_PUBLIC_URL, {
            auth: jwt !== null ? {
                token: jwt
            } : undefined
        });
        socket.on("connect_error", () => handleSetNetworkDown(true));
        return socket;
    }, [jwt, handleSetNetworkDown]);

    return (
        <SocketProvider value={socket}>
            <ResourceProvider value={{axios, routeFunction: route}}>
                <Provider value={{axios, jwt, setJwt, networkDown}}>
                    {children}
                </Provider>
            </ResourceProvider>
        </SocketProvider>
    )
}