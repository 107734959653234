import "@/css/components/month-day-select.scss";
import { Column } from "@enymo/react-layout";
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { MonthDaySelectItem, RadioGroup } from "../glidespec";
import { FormInputProps } from "../types";

interface MonthDaySelectProps extends FormInputProps<(number | "last")> {
    label?: string
    style?: CSSProperties
}

export default function MonthDaySelectSingle({
    label,
    style,
    ...props
}: MonthDaySelectProps) {
    const {t} = useTranslation();

    return (
        <Column gap="8px" style={style}>
            {label && <label className="input-label">{label}</label>}
            <RadioGroup className="month-day-select-grid" handlesError {...props}>
                {Array<void>(31).fill().map((_, index) => (
                    <MonthDaySelectItem key={index} value={index + 1}>{index + 1}</MonthDaySelectItem>
                ))}
                <MonthDaySelectItem className="last" value="last">{t("input.monthDaySelect.last")}</MonthDaySelectItem>
            </RadioGroup>
        </Column>
    )
}