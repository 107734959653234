import "@/css/pages/settings/checkout.scss";
import Back from "@/js/Components/Back";
import Divider from "@/js/Components/Divider";
import InvoiceAddressFields, { InvoiceAddressForm } from "@/js/Components/InvoiceAddressFields";
import Loadable from "@/js/Components/Loadable";
import Tooltip, { TooltipParent } from "@/js/Components/Tooltip";
import Wallet from "@/js/Components/Wallet";
import { useApp } from "@/js/Providers/AppProvider";
import { useUser } from "@/js/Providers/UserProvider";
import { stripePromise, SubscriptionTier } from "@/js/common";
import { Button, Input } from "@/js/glidespec";
import { usePaymentMethods, userTransformer } from "@/js/resources";
import plans from "@/json/plans.json";
import BadgePercent from "@/svg/badge-percent-solid.svg?react";
import ShieldIcon from "@/svg/shield-halved-solid.svg?react";
import XMark from "@/svg/xmark-regular.svg?react";
import { Form, setFormErrors, setFormValues, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import { Elements, ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { AxiosError } from "axios";
import classNames from "classnames";
import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import route from "ziggy-js";

interface CheckoutForm extends InvoiceAddressForm {
    additional_social_accounts: number;
    payment_method: string;
}

interface CheckoutPreview {
    discounts: {
        new: boolean,
        code: string | null,
        amount_off: number | null,
        percent_off: number | null,
        effective_off: number,
        duration: "forever" | "once" | "repeating",
        duration_in_months: number | null
    }[],
    price_plan: number,
    price_social_account: number,
    subtotal: number,
    tax: {
        amount: number,
        rate: number,
        reverse_charge: boolean
    } | null,
    vat_id_required: boolean,
    total: number,
    recurring: number,
    proration: number | null,
    proration_date: number,
    new: boolean,
    session: string
}

interface PromoCode {
    id: string,
    code: string,
    amount_off: number | null,
    percent_off: number | null,
    duration: "forever" | "once" | "repeating",
    duration_in_months: number | null
}

interface PromoCodeSubmit {
    code: string
}

export default function Checkout() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { user, update } = useUser();
    assertNotNull(user);
    const [searchParams] = useSearchParams();
    const form = useForm<CheckoutForm>({
        shouldUnregister: true,
    });
    const {axios} = useApp();

    useEffect(() => {
        setFormValues(form, {
            invoice_name: user.invoice_name ?? "",
            invoice_address_1: user.invoice_address_1 ?? "",
            invoice_address_2: user.invoice_address_2 ?? "",
            invoice_postal_code: user.invoice_postal_code ?? "",
            invoice_city: user.invoice_city ?? "",
            invoice_country: user.invoice_country?.toLowerCase() ?? "de",
            invoice_vat_id: user.invoice_vat_id ?? "",
            invoice_state: user.invoice_state ?? "",
            invoice_vat_type: user.invoice_vat_type ?? undefined,
            additional_social_accounts: user.billed_social_accounts,
        });
    }, [user]);

    const [paymentMethods, { loading: paymentMethodsLoading }] = usePaymentMethods();
    const [preview, setPreview] = useState<CheckoutPreview | null>(null);
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);
    const [inputPromoCode, setInputPromoCode] = useState(false);
    const addedPromoCode = useMemo(() => preview?.discounts.find(({new: isNew}) => isNew)?.code ?? null, [preview]);
    const totalDiscounts = useMemo(() => preview?.discounts.reduce((prev, {effective_off}) => prev + effective_off, 0), [preview]);

    const selectedBillingPeriod = searchParams.get("period") as "monthly" | "yearly";
    const selectedSubscriptionTier = searchParams.get("plan") as SubscriptionTier;
    const selectedPlan = plans[selectedSubscriptionTier];

    const additionalAccounts = form.watch("additional_social_accounts") ?? 0;

    const timerId = useRef<number>();

    const promoCodePortal = useRef<HTMLDivElement>(null);
    const promoCodeForm = useForm<PromoCodeSubmit>();
    const promoCodeFormId = useId();

    const handleSubmitPromoCode: SubmitHandler<PromoCodeSubmit> = async ({code}) => {
        setLoading(true);
        try {
            await handleRetrievePreview(code);
        }
        finally {
            setLoading(false);
        }
        setInputPromoCode(false);
    }

    const handleRetrievePreview = useCallback(async (promoCode?: string) => {
        const {
            additional_social_accounts,
            invoice_country,
            invoice_vat_id,
            invoice_vat_type
        } = form.getValues();
        const response = await axios.post(route("checkout.preview"), {
            promo_code: promoCode,
            subscription_tier: selectedSubscriptionTier,
            billing_period: selectedBillingPeriod,
            additional_social_accounts,
            invoice_country,
            invoice_vat_id,
            invoice_vat_type
        });
        setPreview(response.data);
    }, [selectedBillingPeriod, selectedSubscriptionTier, setLoading, setPreview])

    const handleLoadCheckout = async () => {
        setLoading(true);
        try {
            await handleRetrievePreview()
        }
        catch (e) {
            if (e instanceof AxiosError) {
                setFormErrors(form, Object.fromEntries(Object.entries(e.response?.data.errors ?? {}).map(([key, value]) => [key, t(value as string)])));
            }
            else {
                throw e;
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        handleLoadCheckout();
        return form.watch((_, {name, type}) => {
            if (name !== undefined && type === "change") {
                if (["invoice_country", "invoice_vat_type", "additional_social_accounts"].includes(name)) {
                    setLoading(true);
                    form.clearErrors();
                    clearTimeout(timerId.current);
                    handleLoadCheckout();
                }
                else if (name === "invoice_vat_id") {
                    setLoading(true);
                    form.clearErrors();
                    clearTimeout(timerId.current);
                    timerId.current = setTimeout(handleLoadCheckout, 2000);
                }
            }
        }).unsubscribe;
    }, [handleRetrievePreview, setLoading])

    const formatPrice = (price?: number) => (((price ?? 0) / 100).toFixed(2).replaceAll('.', ',') ?? "") + " €";


    const isPlanDifferent = useMemo(() => (
        user.billed_tier !== selectedSubscriptionTier ||
        user.billed_social_accounts != additionalAccounts ||
        user.billing_period !== selectedBillingPeriod
    ), [user, selectedSubscriptionTier, additionalAccounts, selectedBillingPeriod]);


    const handleSubmit = (elements: StripeElements | undefined, stripe: Stripe | null) => async (data: CheckoutForm) => {
        if (!preview) {
            return;
        }

        setFormLoading(true);

        if (paymentMethods.length === 0 && (await elements?.submit())?.error !== undefined) {
            setFormLoading(false);
            return;
        }

        update(userTransformer({
            invoice_address_1: data.invoice_address_1,
            invoice_address_2: data.invoice_address_2,
            invoice_city: data.invoice_city,
            invoice_country: data.invoice_country,
            invoice_name: data.invoice_name,
            invoice_postal_code: data.invoice_postal_code,
            invoice_state: data.invoice_state,
            invoice_vat_id: data.invoice_vat_id,
            invoice_vat_type: data.invoice_vat_type,
        }), 'local-only'); // Update the user locally

        const response = await axios.post<{
            type: "payment" | "setup" | "no-action";
            upgrade: boolean,
            client_secret: string;
        }>(route("checkout"), {
            ...data,
            subscription_tier: selectedSubscriptionTier,
            billing_period: selectedBillingPeriod,
            session: preview.session,
            proration_date: preview.proration_date,
            promo_code: addedPromoCode
        });

        if (response.data.type === "no-action") {
            // No action with stripe required, still have to wait for the socket event
            navigate(`/app/settings/subscription/payment-success?type=${response.data.upgrade ? "upgrade" : "downgrade"}`);
            return;
        }

        const confirmIntent = response.data.type === "setup" ? stripe?.confirmSetup : stripe?.confirmPayment;

        await confirmIntent?.({
            elements,
            clientSecret: response.data.client_secret,
            confirmParams: {
                return_url: `${window.location.origin}/app/settings/subscription/payment-success?type=setup`,
                payment_method_data: {
                    billing_details: {
                        address: {
                            country: data.invoice_country,
                            city: data.invoice_city,
                            postal_code: data.invoice_postal_code,
                            line1: data.invoice_address_1,
                            line2: data.invoice_address_2,
                        },
                        name: data.invoice_name,
                        email: user.email,
                    }
                },
            },
            redirect: "if_required",
        });

        navigate("/app/settings/subscription/payment-success?type=setup");
    }

    if (selectedSubscriptionTier === "free" || !['monthly', 'yearly'].includes(selectedBillingPeriod)) {
        return <Navigate to="/app/settings/subscription" />
    }

    return (
        <Loadable loading={preview === null} fade>
            <Elements stripe={stripePromise} options={{
                mode: "subscription",
                amount: preview?.total ?? 0,
                currency: "eur",
                payment_method_types: ["card", "paypal"],
            }}>
                <ElementsConsumer>
                    {({ elements, stripe }) => (
                        <Form form={form} onSubmit={handleSubmit(elements ?? undefined, stripe)}>
                            <div className="checkout">
                                <Column gap="30px" maxWidth="450px" width="100%" className="details">
                                    <Back to="/app/settings/subscription" />
                                    {!paymentMethodsLoading && (paymentMethods.length > 0 ? (
                                        <Wallet
                                            name="payment_method"
                                            options={{
                                                required: t("settings.checkout.paymentMethod.required")
                                            }}
                                        />
                                    ) : (
                                        <PaymentElement options={{
                                            layout: "accordion",
                                            fields: {
                                                billingDetails: {
                                                    name: "never",
                                                    email: "never",
                                                    address: {
                                                        city: "never",
                                                        country: "never",
                                                        line1: "never",
                                                        line2: "never",
                                                        postalCode: "never",
                                                    }
                                                },
                                            }
                                        }} />
                                    ))}
                                    <InvoiceAddressFields
                                        vatIdRequired={preview?.vat_id_required}
                                    />
                                </Column>
                                <Column hAlign="center" flex={1}>
                                    <Column gap="25px" className="summary-wrapper">
                                        <Column className="summary" gap="50px">
                                            <h3>{t("settings.checkout.summary")}</h3>
                                            <Column gap="20px">
                                                <Row align="space">
                                                    <span>
                                                        {t("settings.checkout.summary.item", {
                                                            socialAccounts: selectedPlan.social_accounts,
                                                            tier: t(`subscriptionTier.${selectedSubscriptionTier}`),
                                                        })}
                                                    </span>
                                                    <span className="price">
                                                        {formatPrice(preview?.price_plan)}
                                                    </span>
                                                </Row>
                                                {selectedPlan.max_additional_accounts > 0 && (
                                                    <Row align="space" vAlign="center">
                                                        <Row gap="12px" vAlign="center">
                                                            <Input
                                                                className="additional-social-accounts"
                                                                name="additional_social_accounts"
                                                                type="select"
                                                            >
                                                                {Array.from({ length: selectedPlan.max_additional_accounts + 1 }, (_, i) => i).map((i) => (
                                                                    <option key={i} value={i}>{i}</option>
                                                                ))}
                                                            </Input>
                                                            <span>
                                                                {t("settings.checkout.summary.additionalAccounts")}
                                                            </span>
                                                        </Row>
                                                        <span className="price">
                                                            {formatPrice((preview?.price_social_account ?? 0) * additionalAccounts)}
                                                        </span>
                                                    </Row>
                                                )}
                                                <div style={{display: inputPromoCode ? "block" : "none"}} ref={promoCodePortal} />
                                                {preview?.discounts.map(({code, amount_off, duration, duration_in_months, percent_off, effective_off, new: isNew}) => (
                                                    <Column gap="5px">
                                                        <Row align="space">
                                                            <Row align="center" gap="5px">
                                                                <span>{code ? t("settings.checkout.promoCode.code", {code: code}) : t("settings.checkout.promoCode.previousCode")}</span>
                                                                {isNew && (
                                                                    <button type="button" onClick={handleLoadCheckout}>
                                                                        <XMark className="remove-promo-code" />
                                                                    </button>
                                                                )}
                                                            </Row>
                                                            <span className="price">{formatPrice(-effective_off)}</span>
                                                        </Row>
                                                        <span className="note">
                                                            {t(`settings.checkout.promoCode.${amount_off ? "amount" : "percent"}.${duration === "repeating" && selectedBillingPeriod === "yearly" && Math.floor(duration_in_months! / 12) ? "once" : duration === "repeating" ? `repeating.${selectedBillingPeriod}` : duration}`, {amount: percent_off ?? (amount_off! / 100).toLocaleString(i18n.language, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }), count: selectedBillingPeriod === "yearly" ? Math.floor(duration_in_months! / 12) : duration_in_months!})}
                                                        </span>
                                                    </Column>
                                                ))}
                                                {preview?.new && !inputPromoCode && addedPromoCode === null && (
                                                    <button type="button" className="promocode-link" onClick={() => setInputPromoCode(true)}>
                                                        <BadgePercent />
                                                        {t("settings.checkout.promoCode.add")}
                                                    </button>
                                                )}
                                            </Column>
                                            <Column gap="15px">
                                                <Divider />
                                                <Row align="space">
                                                    <span>{t("settings.checkout.summary.subtotal")}</span>
                                                    <span className="price">
                                                        {formatPrice((preview?.subtotal ?? 0) - (totalDiscounts ?? 0))}
                                                    </span>
                                                </Row>
                                                <Row gap="12px" vAlign="bottom" align="space">
                                                    {loading ? (
                                                        <span>{t("settings.checkout.summary.vat.loadingNote")}</span>
                                                    ) : (
                                                        !preview?.tax ? (
                                                            <span>{t("settings.checkout.summary.vat.missingNote")}</span>
                                                        ) : (
                                                            <>
                                                                <span>{t("settings.checkout.summary.vat", { vat: preview.tax.rate })}</span>
                                                                {preview.tax.reverse_charge ? (
                                                                    <span className="note">{t("settings.checkout.summary.vat.reverseCharge")}</span>
                                                                ) : (
                                                                    <span className="price">{formatPrice(preview.tax.amount)}</span>
                                                                )} 
                                                            </>
                                                        )
                                                    )}

                                                </Row>
                                                <Divider />
                                                <Row align="space">
                                                    <span>{t("settings.checkout.summary.total")}</span>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="total-note">{t("settings.checkout.summary.billingPeriod", {
                                                                    billingPeriod: t(`billingPeriod.${selectedBillingPeriod}`)
                                                                })}</td>
                                                                <td className="total">{formatPrice(preview?.recurring)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="total-note">{t("settings.checkout.summary.todayToPay")}</td>
                                                                <td className={classNames("total", "green")}>{formatPrice(preview?.total)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </Row>
                                            </Column>
                                        </Column>
                                        <TooltipParent disabled={!!preview?.tax} style={{ width: "100%" }}>
                                            <Button variant="primary" loading={formLoading} disabled={formLoading || !isPlanDifferent || !preview?.tax || loading} submit>
                                                {isPlanDifferent ? t("settings.checkout.submit") : t("planCard.currentPlan")}
                                                <Tooltip>{t(`settings.checkout.submit.tooltip.${formLoading ? "loading" : "fieldsMissing"}`)}</Tooltip>
                                            </Button>
                                        </TooltipParent>
                                        <Column className="checkout-privacy-note" hAlign="center" gap="6px">
                                            <ShieldIcon />
                                            <span>{t("settings.checkout.privacyNote")}</span>
                                        </Column>
                                    </Column>
                                </Column>
                            </div>
                        </Form>
                    )}
                </ElementsConsumer>
            </Elements>
            <Form id={promoCodeFormId} form={promoCodeForm} onSubmit={handleSubmitPromoCode}>
                {promoCodePortal.current && createPortal(
                    <Row gap="10px">
                        <Input flex={1} name="code" form={promoCodeFormId} placeholder={t("settings.checkout.promoCode.placeholder")} options={{
                            required: t("settings.checkout.promoCode.required")
                        }} />
                        <Button variant="primary" form={promoCodeFormId} submit>{t("settings.checkout.promoCode.apply")}</Button>
                    </Row>,
                    promoCodePortal.current
                )}
            </Form>
        </Loadable >
    )
}