import "@/css/pages/settings/subscription.scss";
import PlanCard from "@/js/Components/PlanCard";
import Popup, { PopupFooter } from "@/js/Components/Popup";
import TabSwitcher, { Tab, Tag } from "@/js/Components/TabSwitcher";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { useUser } from "@/js/Providers/UserProvider";
import { SubscriptionTier, SubscriptionTierPrices, subscriptionTiers } from "@/js/common";
import { Button } from "@/js/glidespec";
import plans from "@/json/plans.json";
import { Column } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import route from "ziggy-js";

export default function Subscription() {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useUser();
    assertNotNull(user);
    const navigate = useNavigate();
    const toast = useToaster();

    const [prices, setPrices] = useState<SubscriptionTierPrices | null>(null);

    useEffect(() => {
        axios.get(route("stripe-prices.index")).then((response) => {
            setPrices(response.data);
        })
    }, [setPrices]);

    const activeType = useMemo(() => (searchParams.get("type") ?? "standard") as "standard" | "agency", [searchParams]);
    const billingPeriod = useMemo(() => (searchParams.get("period") ?? "monthly") as "monthly" | "yearly", [searchParams]);
    const [selectedPlan, setSelectedPlan] = useState<SubscriptionTier | null>(null);

    const setActiveType = (type: "standard" | "agency") => {
        setSearchParams({ type, period: billingPeriod })
    };

    const setBillingPeriod = (period: "monthly" | "yearly") => {
        setSearchParams({ period, type: activeType})
    };

    const handleSetSelectedPlan = async (plan: SubscriptionTier) => {
        if (subscriptionTiers.indexOf(plan) < subscriptionTiers.indexOf(user.billed_tier)) {
            setSelectedPlan(plan);
            return;
        }
        navigate(`/app/settings/subscription/checkout?plan=${plan}&period=${billingPeriod}`);
    };

    const handleDowngradePopupSubmitted = async () => {
        if (selectedPlan === "free") {
            await axios.post(route("checkout.cancel"));
            toast({
                type: "success",
                title: t("subscription.downgrade.success.title"),
                text: t("subscription.downgrade.success.text"),
            });
            setSelectedPlan(null);
        }
        else {
            navigate(`/app/settings/subscription/checkout?plan=${selectedPlan}&period=${billingPeriod}`);
        }
    }

    return (
        <Column maxWidth="920px" width="100%" style={{ alignSelf: "center" }} gap="40px" className="subscription">
            <div className="tab-switcher-container">
                <TabSwitcher value={activeType} onChange={setActiveType}>
                    <Tab value="standard">{t("subscription.standard")}</Tab>
                    <Tab value="agency">{t("subscription.agency")}</Tab>
                </TabSwitcher>
                <TabSwitcher value={billingPeriod} onChange={setBillingPeriod}>
                    <Tab value="yearly">
                        <span>{t("subscription.yearly")}</span>
                        <Tag>-20%</Tag>
                    </Tab>
                    <Tab value="monthly">{t("subscription.monthly")}</Tab>
                </TabSwitcher>
            </div>
            <div className={classNames("plan-cards", activeType)}>
                {activeType === "standard" ? (
                    <>
                        <PlanCard
                            billingPeriod={billingPeriod}
                            size="small"
                            plan={{
                                name: "free",
                                price: 0,
                                ...plans.free
                            }}
                            onSelectPlan={handleSetSelectedPlan}
                        />
                        <PlanCard
                            billingPeriod={billingPeriod}
                            size="large"
                            plan={{
                                name: "pro",
                                price: prices?.pro?.[billingPeriod].subscription,
                                ...plans.pro
                            }}
                            onSelectPlan={handleSetSelectedPlan}
                        />
                        <PlanCard
                            billingPeriod={billingPeriod}
                            size="large"
                            plan={{
                                name: "plus",
                                price: prices?.plus?.[billingPeriod].subscription,
                                ...plans.plus
                            }}
                            onSelectPlan={handleSetSelectedPlan}
                        />
                    </>
                ) : (
                    <>
                        <PlanCard
                            billingPeriod={billingPeriod}
                            size="large"
                            plan={{
                                name: "agency_1",
                                price: prices?.agency_1?.[billingPeriod].subscription,
                                ...plans.agency_1
                            }}
                            onSelectPlan={handleSetSelectedPlan}
                        />
                        <PlanCard
                            billingPeriod={billingPeriod}
                            size="large"
                            plan={{
                                name: "agency_2",
                                price: prices?.agency_2?.[billingPeriod].subscription,
                                ...plans.agency_2
                            }}
                            onSelectPlan={handleSetSelectedPlan}
                        />
                    </>
                )}
            </div>
            {selectedPlan && (
                <Popup width="100%" maxWidth="500px" onBackgroundClick={() => setSelectedPlan(null)}>
                    <Column padding="30px 25px 90px" gap="30px">
                        <h1>{t("subscription.downgradePopup.title")}</h1>
                        <p>
                            <Trans i18nKey="subscription.downgradePopup.text" values={{
                                currentPlan: t(`subscriptionTier.${user.billed_tier}`),
                                newPlan: t(`subscriptionTier.${selectedPlan}`),
                                deadline: t('dateFormat', {
                                    day: "TMP",
                                    month: "TMP",
                                    year: "TMP",
                                }),
                            }}>
                                First line <br /> <br />
                                Second line <br /> <br />
                                Third line <br /> <br />
                                Fourth line
                            </Trans>
                        </p>
                    </Column>
                    <PopupFooter height="80px" vAlign="center" align="end" gap="25px" padding="0 25px">
                        <Button variant="secondary" onClick={() => setSelectedPlan(null)}>{t("subscription.downgradePopup.cancel")}</Button>
                        <Button variant="primary" onClick={handleDowngradePopupSubmitted}>{t("subscription.downgradePopup.submit")}</Button>
                    </PopupFooter>
                </Popup>
            )}


        </Column>
    )
}