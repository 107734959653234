import { Column } from "@enymo/react-layout";
import useScreenSize from "@enymo/react-screen-size-hook";
import { assertNotNull } from "@enymo/ts-nullsafe";
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router";
import CampaignHeader from "../Components/CampaignHeader";
import Menu, { MenuItem } from "../Components/Menu";
import { useUser } from "../Providers/UserProvider";
import { useCampaigns } from "../resources";
import { useTitle } from "./Main";

const breakpoint = 768;

export default function CampaignSettings() {
    const {t} = useTranslation();
    const {campaignId} = useParams();
    assertNotNull(campaignId, "A campaign id must be provided on this page");
    const [campaign] = useCampaigns({id: Number(campaignId)});
    const {user} = useUser();

    const {width} = useScreenSize();
    const mobile = width < breakpoint;

    useTitle(t("campaigns.settings"));

    return (
        <Column flex={1}>
            <CampaignHeader campaignId={Number(campaignId)} />
            <Column style={{ overflowY: "auto" }} flex={1}>
                <Column padding={mobile ? "35px 0 0 0 " : "35px 50px 0 50px"} gap="60px">
                    <Menu>
                        <MenuItem to={`/app/campaigns/${campaignId}/settings/general`}>{t("campaigns.settings.general")}</MenuItem>
                        {campaign?.is_owner && <MenuItem to={`/app/campaigns/${campaignId}/settings/social-accounts`}>{t("campaigns.settings.socialAccounts")}</MenuItem>}
                        <MenuItem to={`/app/campaigns/${campaignId}/settings/posting-times`}>{t("campaigns.settings.postingTimes")}</MenuItem>
                        <MenuItem to={`/app/campaigns/${campaignId}/settings/timezone`}>{t("campaigns.settings.timezone")}</MenuItem>
                        <MenuItem to={`/app/campaigns/${campaignId}/settings/hashtags`}>{t("campaigns.settings.hashtags")}</MenuItem>
                        <MenuItem to={`/app/campaigns/${campaignId}/settings/ai`}>{t("campaigns.settings.ai")}</MenuItem>
                        {campaign?.is_owner && <MenuItem to={`/app/campaigns/${campaignId}/settings/share`}>{t("campaigns.settings.share")}</MenuItem>}
                        <MenuItem to={`/app/campaigns/${campaignId}/settings/misc`}>{t("campaigns.settings.misc")}</MenuItem>
                        {user?.importer && <MenuItem to={`/app/campaigns/${campaignId}/settings/importer`}>{t("campaigns.settings.importer")}</MenuItem>}
                    </Menu>
                </Column>
                <Column flex={1}>
                    <Outlet />
                </Column>
            </Column>
        </Column>
    )
}