import "@/css/pages/main/admin/users.scss";
import Popup, { PopupFooter } from "@/js/Components/Popup";
import SearchField from "@/js/Components/SearchField";
import { Style } from "@/js/Components/Style";
import Switcher from "@/js/Components/Switcher";
import Table from "@/js/Components/Table";
import { useTitle } from "@/js/Layouts/Main";
import { EmailRegex, SubscriptionTier, pad, subscriptionTiers } from "@/js/common";
import { Button, Checkbox, Input } from "@/js/glidespec";
import { useUsers } from "@/js/resources";
import GearIcon from "@/svg/gear-regular.svg?react";
import ExclamationIcon from "@/svg/triangle-exclamation-duotone.svg?react";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import { Column, Row } from "@enymo/react-layout";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

interface UserSettingsForm {
    "first_name": string;
    "last_name": string;
    "email": string;
    "assigned_subscription_tier": SubscriptionTier | "none";
    "assigned_subscription_expires_at": string | null;
    "active": boolean;
}

interface CloseAccountForm {
    "password": string;
}

export default function Users() {

    const { t } = useTranslation();
    useTitle(t("admin.users"));

    const [search, setSearch] = useState("");

    const [users, { update, destroy }] = useUsers();

    const filteredUsers = useMemo(() => users.filter((user) => (
        user.first_name.toLowerCase().includes(search.toLowerCase()) ||
        user.last_name.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())
    )), [users, search]);

    const userSettingsForm = useForm<UserSettingsForm>();
    const closeAccountForm = useForm<CloseAccountForm>();

    const [selectedUserState, setSelectedUserState] = useState<{
        id: number | string;
        activePopup: "settings" | "closeAccount";
    } | null>(null);

    const selectedUser = useMemo(() => users.find((user) => user.id == selectedUserState?.id), [users, selectedUserState?.id]);

    useEffect(() => {
        if (selectedUser) {
            userSettingsForm.reset({
                "first_name": selectedUser.first_name,
                "last_name": selectedUser.last_name,
                "email": selectedUser.email,
                "active": !selectedUser.restricted,
            });
            const date = selectedUser.assigned_subscription_expires_at ?
                selectedUser.assigned_subscription_expires_at.getFullYear() +
                '-' + pad(selectedUser.assigned_subscription_expires_at.getMonth() + 1) +
                '-' + pad(selectedUser.assigned_subscription_expires_at.getDate()) : null;
            userSettingsForm.setValue("assigned_subscription_tier", selectedUser.assigned_tier ?? "none");
            userSettingsForm.setValue("assigned_subscription_expires_at", date);
        }
    }, [selectedUser, userSettingsForm.reset, userSettingsForm.setValue])

    const accountActive = userSettingsForm.watch("active");

    const handleUserSettingsSubmit: SubmitHandler<UserSettingsForm> = async ({
        assigned_subscription_tier,
        assigned_subscription_expires_at,
        active,
        ...data
    }) => {
        if (selectedUser) {
            await update(selectedUser.id, {
                ...data,
                restricted: !active,
                assigned_tier: assigned_subscription_tier === "none" ? null : assigned_subscription_tier,
                assigned_subscription_expires_at: assigned_subscription_tier === "none" ? null : assigned_subscription_expires_at,
            }, 'immediate');
            setSelectedUserState(null);
        }
    }

    const handleCloseAccountSubmit: SubmitHandler<CloseAccountForm> = async ({ password }) => {
        if (selectedUser) {
            await destroy(selectedUser.id, 'on-success', {
                headers: {
                    Authorization: `Password ${password}`,
                },
            });
            setSelectedUserState(null);
        }
    }

    return (
        <Column padding="50px" gap="30px" className="admin-users">
            <Row align="end">
                <SearchField placeholder={t("admin.users.search")} maxWidth="320px" value={search} onChange={setSearch} />
            </Row>
            <Table
                head={[
                    {
                        label: t("admin.users.lastName"),
                    },
                    {
                        label: t("admin.users.firstName"),
                    },
                    {
                        label: t("admin.users.email"),
                    },
                    {
                        label: t("admin.users.billedSubscription"),
                    },
                    {
                        label: t("admin.users.assignedSubscription"),
                        fillWidth: true,
                    },
                    {
                        label: t("admin.users.actions"),
                        cellAlignment: "right",
                    },
                ]}
                rows={filteredUsers.map((user) => {
                    const id = user.id == "me" ? -1 : user.id;
                    return {
                        id: id,
                        data: [
                            user.first_name,
                            user.last_name,
                            <Style className="thin">{user.email}</Style>,
                            <Style className="thin">
                                {t(`subscriptionTier.${user.billed_tier}`)}
                            </Style>,
                            <Style className="thin">
                                {user.assigned_tier ? t(`subscriptionTier.${user.assigned_tier}`) : "-"}
                            </Style>,
                            <button onClick={() => setSelectedUserState({
                                id,
                                activePopup: "settings",
                            })}><GearIcon height="16px" /></button>,
                        ],
                    };
                })}
            />
            {selectedUserState?.activePopup === "settings" && (
                <Popup
                    onBackgroundClick={() => setSelectedUserState(null)}
                    className="admin-users-popup"
                    maxWidth="500px"
                    width="100%"
                >
                    <Form form={userSettingsForm} onSubmit={handleUserSettingsSubmit}>
                        <Column gap="30px" padding="30px 25px 40px">
                            <h1>{t("admin.users.settings.title")}</h1>
                            <Column gap="20px">
                                <Row gap="10px">
                                    <Input
                                        label={t("input.firstName")}
                                        name="first_name"
                                        options={{
                                            required: t("admin.users.firstName.required"),
                                        }}
                                    />
                                    <Input
                                        label={t("input.lastName")}
                                        name="last_name"
                                        options={{
                                            required: t("admin.users.lastName.required"),
                                        }}
                                    />
                                </Row>
                                <Input
                                    label={t("input.email")}
                                    name="email"
                                    options={{
                                        required: t("input.email.required"),
                                        pattern: {
                                            value: EmailRegex,
                                            message: t("input.email.pattern"),
                                        },
                                    }}
                                />
                            </Column>
                            <Column gap="20px">
                                <Row gap="10px">
                                    <p>{t("admin.users.billedSubscription")}:</p>
                                    <p><strong>{t(`subscriptionTier.${selectedUser?.billed_tier}`)}</strong></p>
                                </Row>
                                <Row gap="10px" className="assigned-subscription">
                                    <Input
                                        label={t("admin.users.assignedSubscription")}
                                        name="assigned_subscription_tier"
                                        type="select"
                                    >
                                        <option value="none">-</option>
                                        {subscriptionTiers.map((tier) => (
                                            <option value={tier} key={tier}>{t(`subscriptionTier.${tier}`)}</option>
                                        ))}
                                    </Input>
                                    <Input
                                        label={t("admin.users.settings.endDate")}
                                        name="assigned_subscription_expires_at"
                                        type="date"
                                        options={{
                                            valueAsDate: true,

                                        }}
                                    />
                                </Row>
                            </Column>
                            <Column gap="20px">
                                <Column gap="12px" className="account-state">
                                    <p><strong>{t("admin.users.settings.accountState")}</strong></p>
                                    <Row gap="16px" vAlign="top">
                                        <Switcher name="active" />
                                        <Column gap="5px" flex="unset">
                                            <p><strong>{t(`admin.users.settings.${accountActive ? "active" : "inactive"}`)}</strong></p>
                                            <span className="note">{t(`admin.users.settings.${accountActive ? "active" : "inactive"}.explanation`)}</span>
                                        </Column>
                                    </Row>
                                </Column>
                                <Row>
                                    <Button
                                        variant="danger"
                                        className="close-account-button"
                                        onClick={() => setSelectedUserState((state) => ({
                                            id: state!.id,
                                            activePopup: "closeAccount",
                                        }))}
                                    >
                                        {t("admin.users.settings.closeAccount")}
                                    </Button>
                                </Row>
                            </Column>
                        </Column>
                        <PopupFooter vAlign="center" align="end" gap="30px" padding="0 25px" height="80px">
                            <Button variant="secondary" onClick={() => setSelectedUserState(null)}>
                                {t("cancel")}
                            </Button>
                            <Button variant="primary" submit>
                                {t("save")}
                            </Button>
                        </PopupFooter>
                    </Form>
                </Popup>
            )}
            {selectedUserState?.activePopup === "closeAccount" && (
                <Popup
                    maxWidth="500px"
                    width="100%"
                    onBackgroundClick={() => setSelectedUserState(null)}
                >
                    <Form form={closeAccountForm} onSubmit={handleCloseAccountSubmit}>
                        <Column padding="30px 25px 40px" gap="30px">
                            <Row gap="15px" vAlign="center">
                                <ExclamationIcon width="40px" height="35px" fill="var(--warning)" />
                                <h1>{t("admin.users.settings.closeAccount")}</h1>
                            </Row>
                            <p>{t("admin.users.closeAccount.explanation")}</p>
                            {(selectedUser?.billed_tier !== "free") && (
                                <Column gap="20px">
                                    <p>
                                        <Trans i18nKey="admin.users.closeAccount.subscriptionHint">
                                            <strong>Hint:</strong>The user has an active subscription. This must be canceled to close the account.
                                        </Trans>
                                    </p>
                                    <Checkbox
                                        name="close_account"
                                        options={{
                                            required: t("admin.users.closeAccount.cancelSubscription.required"),
                                        }}
                                    >
                                        <span style={{
                                            fontWeight: 500,
                                        }}>{t("admin.users.closeAccount.cancelSubscription")}</span>
                                    </Checkbox>
                                </Column>
                            )}
                            <Input name="password" label={t("input.password")} type="password" options={{
                                required: t("admin.users.closeAccount.password.required"),
                            }} />
                        </Column>
                        <PopupFooter padding="0 25px" height="80px" vAlign="center" align="end" gap="35px">
                            <Button variant="secondary" onClick={() => setSelectedUserState(null)}>
                                {t("cancel")}
                            </Button>
                            <Button variant="danger" submit>
                                {t("admin.users.settings.closeAccount")}
                            </Button>
                        </PopupFooter>
                    </Form>
                </Popup>
            )}
        </Column>
    )
}