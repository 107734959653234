import "@/css/components/foldable.scss";
import { Column } from "@enymo/react-layout";
import React from "react";
import useBreakpoint from "../Hooks/BreakpointHook";

// Note: It's no longer foldable, but the container is convenient, so it stays
export default function Foldable({
    title,
    children
}: {
    title?: React.ReactNode,
    children: React.ReactNode
}) {
     const screenSize = useBreakpoint({ "mobile": 1230, "desktop": 1900 }) ?? "wide-desktop";

    return (
        <Column
            gap="30px"
            width={["desktop", "wide-desktop"].includes(screenSize) ? "350px" : undefined}
            minWidth={["desktop", "wide-desktop"].includes(screenSize) ? undefined : "290px"}
        >
            <Column className="settings-wrapper" gap="18px">
                {title && <h3>{title}</h3>}
                {children}
            </Column>
        </Column>
    )
}