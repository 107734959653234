import "@/css/pages/settings/payment-success.scss";
import Loader from "@/js/Components/Loader";
import { useSanitizedString } from "@/js/Hooks/SanitizedParamHook";
import { useApp } from "@/js/Providers/AppProvider";
import { useUser } from "@/js/Providers/UserProvider";
import { Button } from "@/js/glidespec";
import CheckIcon from "@/svg/circle-check-solid.svg?react";
import WarningIcon from "@/svg/triangle-exclamation-solid.svg?react";
import { Column, Row } from "@enymo/react-layout";
import useSocket from "@enymo/react-socket-hook";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import route from "ziggy-js";

function PaymentSuccessText({title, text, button}: {
    title: string,
    text: string,
    button?: {
        to: string,
        children: React.ReactNode
    }
}) {
    return (
        <Column gap="20px" align="center" hAlign="center" maxWidth="580px">
            <h1>{title}</h1>
            <p style={{ textAlign: "center" }}>{text}</p>
            {button && (
                <Row align="center">
                    <Button
                        to={button.to}
                        variant="primary"
                    >
                        {button.children}
                    </Button>
                </Row>
            )}
        </Column>
    )
}

type CheckoutStatus = "processing" | "success" | "error";

export default function PaymentSuccess() {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const type = useSanitizedString(searchParams.get("type"), ["setup", "upgrade", "downgrade"], "upgrade", [searchParams]);
    const redirectStatus = useMemo(() => searchParams.get("redirect_status"), [searchParams]);
    const interval = useRef<number>();
    const [result, setResult] = useState<CheckoutStatus>("processing");
    const {user} = useUser();
    const {axios} = useApp();

    useSocket<CheckoutStatus>("checkout.status", result => {
        clearInterval(interval.current);
        setResult(result);
    }, [setResult, interval]);

    const handleFetchStatus = useCallback(async () => {
        const response = await axios.get(route("checkout.status"));

        if (response.data.status !== "processing") {
            clearInterval(interval.current);
        }

        setResult(response.data.status);
    }, [axios, setResult, interval]);

    useEffect(() => {
        handleFetchStatus();
        interval.current = setInterval(handleFetchStatus, 10000);
        return () => clearInterval(interval.current);
    }, [handleFetchStatus, interval]);

    return (
        <Column gap="30px" hAlign="center" className="payment-successful">
            {(redirectStatus === "failed" || result === "error") ? <>
                <WarningIcon width="70px" height="61px" fill="var(--error)" />
                <PaymentSuccessText
                    title={t("settings.paymentSuccess.failed")}
                    text={t("settings.paymentSuccess.failed.text")}
                    button={{
                        to: "/app/settings/subscriptions",
                        children: t("settings.paymentSuccess.failed.button")
                    }}
                />
            </> : type === "setup" && (user?.billed_tier ?? "free") === "free" ? <>
                <Loader width="70px" fill="var(--primary-500)" />
                <PaymentSuccessText
                    title={t("settings.paymentSuccess.processing")}
                    text={t("settings.paymentSuccess.processing.text")}
                />
            </> : <>
            <CheckIcon width="70px" height="70px" fill="var(--green)" />
                <PaymentSuccessText
                    title={t(`settings.paymentSuccess.${type === "downgrade" ? "successful-modification" : "successful-payment"}`)}
                    text={t(`settings.paymentSuccess.${type === "downgrade" ? "successful-modification" : "successful-payment"}.text`)}
                    button={{
                        to: "/app/dashboard",
                        children: t("settings.paymentSuccess.successful-payment.button")
                    }}
                />
            </>}
        </Column>
    )
}