import "@/css/app.scss";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Gate from "./Gate";
import CampaignSettings from "./Layouts/CampaignSettings";
import Hashtags from "./Layouts/Hashtags";
import Lockscreen from "./Layouts/Lockscreen";
import Main from "./Layouts/Main";
import Settings from "./Layouts/Settings";
import SubscriptionLayout from "./Layouts/Subscription";
import ForgotPassword from "./Pages/Lockscreen/ForgotPassword";
import Login from "./Pages/Lockscreen/Login";
import ResetPassword from "./Pages/Lockscreen/ResetPassword";
import SignUp from "./Pages/Lockscreen/SignUp";
import AccountRestricted from "./Pages/Main/AccountRestricted";
import AISettings from "./Pages/Main/Admin/AISettings";
import Users from "./Pages/Main/Admin/Users";
import Analytics from "./Pages/Main/Analytics";
import Calendar from "./Pages/Main/Calendar";
import Campaigns from "./Pages/Main/Campaigns/Campaigns";
import Post from "./Pages/Main/Campaigns/Posts/Post";
import Posts from "./Pages/Main/Campaigns/Posts/Posts";
import CampaignAISettings from "./Pages/Main/Campaigns/Settings/AISettings";
import CampaignsGeneral from "./Pages/Main/Campaigns/Settings/General";
import CampaignHashtags from "./Pages/Main/Campaigns/Settings/Hashtags";
import Importer from "./Pages/Main/Campaigns/Settings/Importer";
import Misc from "./Pages/Main/Campaigns/Settings/Misc";
import PostingTimes from "./Pages/Main/Campaigns/Settings/PostingTimes";
import Share from "./Pages/Main/Campaigns/Settings/Share";
import CampaignSocialAccounts from "./Pages/Main/Campaigns/Settings/SocialAccounts";
import Timezone from "./Pages/Main/Campaigns/Settings/Timezone";
import Dashboard from "./Pages/Main/Dashboard";
import Lists from "./Pages/Main/Hashtags/Lists";
import Research from "./Pages/Main/Hashtags/Research";
import General from "./Pages/Main/Settings/General";
import Invoices from "./Pages/Main/Settings/Invoices";
import Password from "./Pages/Main/Settings/Password";
import PaymentInfo from "./Pages/Main/Settings/PaymentInfo";
import Checkout from "./Pages/Main/Settings/Subscription/Checkout";
import PaymentSuccess from "./Pages/Main/Settings/Subscription/PaymentSuccess";
import Subscription from "./Pages/Main/Settings/Subscription/Subscription";
import SocialAccounts from "./Pages/Main/SocialAccounts";
import PostLogin from "./Pages/PostLogin";
import CommonResourcesProvider from "./Providers/CommonResourcesProvider";
import PostsProvider from "./Providers/PostsProvider";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Navigate to="/app" replace />} />
                <Route path="login" element={<Gate realm="login" />}>
                    <Route element={<Lockscreen />}>
                        <Route index element={<Login />} />
                        <Route path="sign-up" element={<SignUp />} />
                        <Route path="forgot-password" element={<ForgotPassword />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                    </Route>
                </Route>
                <Route path="post-login" element={<Gate realm="post-login" />}>
                    <Route index element={<PostLogin />} />
                </Route>
                <Route path="account-restricted" element={<Gate realm="account-restricted" />}>
                    <Route index element={<AccountRestricted />} />
                </Route>
                <Route path="app" element={<Gate realm="app" />}>
                    <Route element={<CommonResourcesProvider children={<Main />} />}>
                        <Route index element={<Navigate to="/app/dashboard" replace />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="campaigns" element={<Campaigns />} />
                        <Route path="campaigns/:campaignId/posts" element={<PostsProvider />}>
                            <Route index element={<Posts />} />
                            <Route path=":postId" element={<Post />} />
                        </Route>
                        <Route path="campaigns/:campaignId/settings" element={<CampaignSettings />}>
                            <Route index element={<Navigate to="general" replace />} />
                            <Route path="general" element={<CampaignsGeneral />} />
                            <Route path="social-accounts" element={<CampaignSocialAccounts />} />
                            <Route path="posting-times" element={<PostingTimes />} />
                            <Route path="timezone" element={<Timezone />} />
                            <Route path="hashtags" element={<CampaignHashtags />} />
                            <Route path="ai" element={<CampaignAISettings />} />
                            <Route path="share" element={<Share />} />
                            <Route path="misc" element={<Misc />} />
                            <Route path="importer" element={<Importer />} />
                        </Route>
                        <Route path="social-accounts" element={<SocialAccounts />} />
                        <Route path="calendar" element={<Calendar />} />
                        <Route path="hashtags" element={<Hashtags />}>
                            <Route index element={<Navigate to="/app/hashtags/lists" replace />} />
                            <Route path="lists" element={<Lists />} />
                            <Route path="research" element={<Research />} />
                        </Route>
                        <Route path="analytics" element={<Analytics />} />
                        <Route path="settings" element={<Settings />}>
                            <Route path="general" element={<General />} />
                            <Route path="password" element={<Password />} />
                            <Route element={<SubscriptionLayout />}>
                                <Route path="subscription">
                                    <Route index element={<Subscription />} />
                                    <Route path="checkout" element={<Checkout />} />
                                    <Route path="payment-success" element={<PaymentSuccess />} />
                                </Route>
                                <Route path="payment-info" element={<PaymentInfo />} />
                            </Route>
                            <Route path="invoices" element={<Invoices />} />
                        </Route>
                        <Route path="admin">
                            <Route path="ai" element={<AISettings />} />
                            <Route path="users" element={<Users />} />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}