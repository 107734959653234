import "@/css/components/plan-card.scss";
import plans from "@/json/plans.json";
import CircleCheckIcon from "@/svg/circle-check-duotone.svg?react";
import FacebookIcon from "@/svg/facebook.svg?react";
import InstagramIcon from "@/svg/instagram.svg?react";
import LinkedinIcon from "@/svg/linkedin.svg?react";
import TiktokIcon from "@/svg/tiktok.svg?react";
import YoutubeIcon from "@/svg/youtube.svg?react";
import { Column, Row } from "@enymo/react-layout";
import { assertNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../Providers/UserProvider";
import { SubscriptionTier, subscriptionTiers } from "../common";
import { Button } from "../glidespec";
import Loader from "./Loader";

interface Plan {
    name: SubscriptionTier,
    price: number | undefined,
    social_accounts: number,
    campaigns: number,
    posts_per_campaign: number,
    texts_per_post: number,
    images_per_post: number,
    ai_text_per_month: number,
    instagram: boolean,
    facebook: boolean,
    tiktok: boolean,
    youtube: boolean,
    linkedin: boolean,
}

function ProgressBar({
    percentage,
    children,
}: {
    percentage: number,
    children?: React.ReactNode,
}) {
    return (
        <Column className="progress-bar">
            {children}
            <div className="progress">
                <div className="bar" style={{ width: `${percentage}%` }} />
            </div>
        </Column>
    )
}

function SocialMediaRow({
    icon: Icon,
    label,
    condition
}: {
    icon?: React.FC<React.SVGProps<SVGSVGElement>>,
    label: React.ReactNode,
    condition: boolean,

}) {
    return condition ? (
        <Row className={"social-media-row"} vAlign="center" gap="10px">
            {Icon ? <Icon width="16px" height="16px" /> : <div style={{ width: "16px", height: "16px" }} />}
            <span>{label}</span>
        </Row>
    ) : null;
}

export default function PlanCard({
    plan,
    size,
    billingPeriod,
    onSelectPlan,
}: {
    plan: Plan,
    size: "small" | "large",
    billingPeriod: "monthly" | "yearly",
    onSelectPlan: (plan: SubscriptionTier) => void,
}) {

    const { t } = useTranslation();
    const { user } = useUser()
    assertNotNull(user);

    const priceLoading = plan.price === undefined;

    const price = useMemo(() => ((plan.price ?? 0) / (billingPeriod === "monthly" ? 1 : 12)).toFixed(2).replaceAll(".", ",") ?? "", [plan.price, billingPeriod]);

    const subscriptionType = useMemo(() => subscriptionTiers.indexOf(plan.name) < 3 ? "standard" : "agency", [plan.name]);

    const upgradeButtonVariant = useMemo(() => plan.name === "pro" || plan.name === "agency_2" ? "primarySmall" : "outlineSmall", [plan.name]);

    const upgradeButtonDisabled = useMemo(() => plan.name === "free" && user.billed_tier === "free", [plan.name, user.billed_tier]);

    const upgradeButtonText = useMemo(() => {
        if (plan.name === user.billed_tier) {
            if (plan.name === "free") {
                return t("planCard.currentPlan");
            }
            if (billingPeriod === user.billing_period) {
                return t("planCard.editPlan");
            }
            return t(`planCard.switchBillingPeriod.${billingPeriod}`);
        }
        if (subscriptionTiers.indexOf(plan.name) < subscriptionTiers.indexOf(user.billed_tier)) {
            return t(`planCard.downgrade.${plan.name}`);
        }
        else {
            return t(`planCard.upgrade.${plan.name}`);
        }
    }, [plan.name, user, billingPeriod]);

    const maxCampaigns = useMemo(() => subscriptionType === "standard" ? plans.pro.campaigns : plans.agency_2.campaigns, [subscriptionType]);
    const maxPostsPerCampaign = useMemo(() => subscriptionType === "standard" ? plans.pro.posts_per_campaign : plans.agency_2.posts_per_campaign, [subscriptionType]);
    const maxTextsPerPost = useMemo(() => subscriptionType === "standard" ? plans.pro.texts_per_post : plans.agency_2.texts_per_post, [subscriptionType]);
    const maxImagesPerPost = useMemo(() => subscriptionType === "standard" ? plans.pro.images_per_post : plans.agency_2.images_per_post, [subscriptionType]);

    return (
        <Column className={classNames("plan-card", plan.name, size)}>
            <Column gap="40px">
                <Column>
                    <Column gap="10px">
                        <span className="name">{t(`subscriptionTier.${plan.name}`)}</span>
                        <span className="social-accounts">{t("planCard.socialAccounts", { count: plan.social_accounts })}</span>
                    </Column>
                    <Column height="105px" padding="15px 0" align="center" gap="15px">
                        <Row vAlign="bottom" gap="12px">
                            {priceLoading ? (
                                <Loader width="30px" fill="var(--primary-500)" />
                            ) : (
                                <span className="price">
                                    {plan.name === "free" ? t("planCard.free") : `€ ${price}`}
                                </span>
                            )}
                            {plan.name !== "free" && (
                                <span className="billing-period">
                                    {t(`planCard.billingPeriod.per.monthly`)}
                                </span>
                            )}
                        </Row>
                        {plan.name !== "free" && (
                            <span className="billing-note">
                                {t("planCard.billingNote", { 
                                    price: `${plan.price?.toFixed(2).replaceAll(".", ",") ?? ""}`,
                                    billingPeriod: t(`subscription.${billingPeriod}`),
                                    perBillingPeriod: t(`planCard.billingPeriod.per.${billingPeriod}`) 
                                })}
                            </span>
                        )}
                    </Column>
                    <Button variant={upgradeButtonVariant} disabled={upgradeButtonDisabled} onClick={() => onSelectPlan(plan.name)}>
                        {upgradeButtonText}
                    </Button>
                </Column>
                <Column gap="30px">
                    <Column gap="15px">
                        <Row gap="6px" className="hashtag-note">
                            <CircleCheckIcon />
                            <span className="text">{t("planCard.hashtagNote")}</span>
                        </Row>
                        <ProgressBar percentage={plan.campaigns / maxCampaigns * 100}>
                            <span>{t("planCard.campaigns", { count: plan.campaigns })}</span>
                        </ProgressBar>
                        <ProgressBar percentage={plan.posts_per_campaign / maxPostsPerCampaign * 100}>
                            <span>{t("planCard.postsPerCampaign", { count: plan.posts_per_campaign })}</span>
                        </ProgressBar>
                        <ProgressBar percentage={plan.texts_per_post / maxTextsPerPost * 100}>
                            <span>{t("planCard.textsPerPost", { count: plan.texts_per_post })}</span>
                        </ProgressBar>
                        <ProgressBar percentage={plan.images_per_post / maxImagesPerPost * 100}>
                            <span>{t("planCard.imagesPerPost", { count: plan.images_per_post })}</span>
                        </ProgressBar>
                        <span className="text">{t("planCard.AITextPerMonth", { count: plan.ai_text_per_month })}</span>
                    </Column>
                    <Column gap="11px">
                        <SocialMediaRow
                            icon={InstagramIcon}
                            label={t("instagram")}
                            condition={plan.instagram}
                        />
                        <SocialMediaRow
                            icon={FacebookIcon}
                            label={t("facebook")}
                            condition={plan.facebook}
                        />
                        <SocialMediaRow
                            icon={TiktokIcon}
                            label={t("tiktok")}
                            condition={plan.tiktok}
                        />
                        <SocialMediaRow
                            icon={import.meta.env.VITE_BRAND_CORRECTNESS_MODE === "true" ? undefined : YoutubeIcon}
                            label={t("youtube")}
                            condition={plan.youtube}
                        />
                        <SocialMediaRow
                            icon={LinkedinIcon}
                            label={t("linkedin")}
                            condition={plan.linkedin}
                        />
                    </Column>
                </Column>
            </Column>
        </Column>
    )
}