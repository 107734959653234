import "@/css/pages/main/campaigns/posts/post.scss";
import Drawer from "@/js/Components/Drawer";
import DropdownButton, { DropdownButtonItem } from "@/js/Components/DropdownButton";
import Foldable from "@/js/Components/Foldable";
import Loader from "@/js/Components/Loader";
import MediaCarousel from "@/js/Components/Post/MediaCarousel";
import TextsColumn from "@/js/Components/Post/TextsColumn";
import PostPreview from "@/js/Components/PostPreview";
import PostSettings from "@/js/Components/PostSettings";
import Tooltip, { TooltipParent } from "@/js/Components/Tooltip";
import useBreakpoint from "@/js/Hooks/BreakpointHook";
import { useTitle } from "@/js/Layouts/Main";
import { useApp } from "@/js/Providers/AppProvider";
import { useConfirmPopup } from "@/js/Providers/ConfirmPopupProvider";
import { useToaster } from "@/js/Providers/ToasterProvider";
import { useUser } from "@/js/Providers/UserProvider";
import { BackButton, Button } from "@/js/glidespec";
import { PostMedium, PostSettingsKeys, PostSettings as PostSettingsType, PostText, useCampaigns, usePostMedia, usePostTexts, usePosts, useUsers } from "@/js/resources";
import plans from "@/json/plans.json";
import postSettingsJson from "@/json/post-settings.json";
import CheckIcon from "@/svg/check-light.svg?react";
import { createRequiredContext } from "@enymo/react-better-context";
import { Column, Row } from "@enymo/react-layout";
import { ReturnList } from "@enymo/react-resource-hook";
import { assertNotNull, isNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router";
import route from "ziggy-js";

const postSettings = postSettingsJson as {
    group: "facebook" | "tiktok" | "youtube",
    settings: ({
        oneOf?: string,
        name: string,
        requiresFeatures?: ("stories")[],
    } & ({
        type: "boolean",
        defaultValue: boolean,
    } | {
        type: "choice",
        defaultValue: string,
        choices: string[],
    }))[]
}[]

function AutosaveIndicator({
    loading,
    mobile = false,
}: {
    loading: boolean,
    mobile?: boolean,
}) {
    const { t } = useTranslation();

    return (
        <Row gap="5px" vAlign="center" className="autosave-indicator">
            {loading ? (
                <>
                    <Loader fill="var(--primary-400)" />
                    {!mobile && <span>{loading ? t("autosave.saving") : t("")}</span>}
                </>
            ) : (
                <>
                    <CheckIcon fill="var(--neutral-700)" />
                    {!mobile && <span>{t("autosave.saved")}</span>}
                </>
            )}
        </Row>
    )
}

function Header({
    autosaveLoading,
    onPostNow,
    onDiscardDraft,
    onDeletePost,
    onPublish,
}: {
    autosaveLoading: boolean,
    onPostNow: () => void,
    onDiscardDraft: () => void,
    onDeletePost: () => void,
    onPublish: () => Promise<void>,
}) {
    const { campaignId, postId } = useParams();
    const { user } = useUser();
    assertNotNull(user, "User must be logged in to access this page");
    const { t } = useTranslation();
    const [campaign] = useCampaigns({ id: Number(campaignId) });
    const [post] = usePosts({
        params: useMemo(() => ({ campaign: campaignId }), [campaignId]),
        id: Number(postId),
    });
    const [campaignOwner] = useUsers({ id: campaign?.owner.id ?? null, autoRefresh: !!campaign });
    const { screenSize } = usePostParams();

    const overLimit = useMemo(() => {
        const plan = campaignOwner?.subscription_tier ? plans[campaignOwner.subscription_tier] : null;
        return post && plan ? (
            post.text_count > plan.texts_per_post || 
            post.media_count > plan.images_per_post ||
            (!plan.stories && post.settings.facebook?.story)
        ) : true;
    }, [post?.text_count, post?.media_count, campaignOwner?.subscription_tier, post?.settings.facebook?.story]);

    const titleAndBadge = useMemo(() => ["desktop", "wide-desktop"].includes(screenSize) ? (
        <Row gap="10px" vAlign="center">
            <span className="title">{campaign?.name}</span>
            {post && (post?.dirty || post.editing_state === "draft") && <span className="badge">{t(`posts.create.note.${post.editing_state === "draft" ? "draft" : "unpublished"}`)}</span>}
        </Row>
    ) : (
        <Column gap="10px">
            <Row>{post && (post?.dirty || post.editing_state === "draft") && <span className="badge">{t(`posts.create.note.${post.editing_state === "draft" ? "draft" : "unpublished"}`)}</span>}</Row>
            <span className="title">{campaign?.name}</span>
        </Column>

    ), [campaign, post, t, screenSize])

    return (
        <div className={classNames("post-header", { mobile: screenSize !== "desktop" && screenSize !== "wide-desktop" })} >
            <Row align="space" vAlign="center" flex={1}>
                <Row gap="30px" vAlign="center">
                    <BackButton to={`/app/campaigns/${campaignId}/posts`}>{t("back")}</BackButton>
                    {["desktop", "wide-desktop"].includes(screenSize) && titleAndBadge}
                </Row>
                <Row gap={screenSize === "mobile" ? "10px" : "25px"}>
                    <AutosaveIndicator loading={autosaveLoading} mobile={screenSize === "mobile"} />
                    <Row gap="10px">
                        <TooltipParent disabled={!overLimit}>
                            <Button
                                variant="primarySmall"
                                onClick={onPublish}
                                disabled={autosaveLoading || !post?.dirty || overLimit}
                            >
                                {t("posts.create.publish")}
                            </Button>
                            <Tooltip>{t("posts.create.overPlanLimit")}</Tooltip>
                        </TooltipParent>
                        <DropdownButton>
                            <DropdownButtonItem
                                disabled={autosaveLoading || overLimit}
                                onClick={onPostNow}
                            >
                                {t("posts.create.menu.postNow")}
                            </DropdownButtonItem>
                            <DropdownButtonItem
                                onClick={onDiscardDraft}
                            >
                                {t("posts.create.menu.discardDraft")}
                            </DropdownButtonItem>
                            <DropdownButtonItem to={`/app/campaigns/${campaignId}/settings/general`}>{t("campaigns.settings")}</DropdownButtonItem>
                            <DropdownButtonItem variant="danger" onClick={onDeletePost}>{t("posts.create.menu.deletePost")}</DropdownButtonItem>
                        </DropdownButton>
                    </Row>
                </Row>
            </Row>
            {screenSize !== "desktop" && screenSize !== "wide-desktop" && titleAndBadge}
        </div>
    )
}

function Preview({ hideTitle = false, ...props }: {
    media: PostMedium[],
    texts: PostText[],
    postId: number
    hideTitle?: boolean
}) {
    const { t } = useTranslation();

    return (
        <Foldable title={hideTitle ? undefined : t("postPreview")}>
            <PostPreview {...props} />
        </Foldable>
    )
}

const [PostParamsProvider, usePostParams] = createRequiredContext<{
    textStoreLoading: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
    screenSize: "mobile" | "desktop" | "wide-desktop",
    textLimitReached: boolean,
    mediaLimitReached: boolean,
}>("PostParamsProvider needs to be in the tree.");

export { usePostParams };

export default function Post() {
    const { campaignId, postId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const screenSize = useBreakpoint({ "mobile": 1230, "desktop": 1900 }) ?? "wide-desktop";
    const { user } = useUser();
    assertNotNull(user, "User must be logged in to access this page");
    assertNotNull(campaignId, "A campaign id must be provided on this page");
    assertNotNull(postId, "A post id must be provided on this page");

    useTitle(t("posts.create"));
    const withPopup = useConfirmPopup();
    const toast = useToaster();

    const { axios } = useApp();

    const [requestsLoading, setRequestsLoading] = useState(0);
    const [textStoreLoading, setTextStoreLoading] = useState(false);

    const [post, { update, error, refresh, destroy }] = usePosts({
        params: useMemo(() => ({ campaign: campaignId }), [campaignId]),
        id: Number(postId),
    });

    const [campaign] = useCampaigns({ id: Number(campaignId) });
    const [campaignOwner] = useUsers({ id: campaign?.owner.id ?? null, autoRefresh: !!campaign });

    useEffect(() => {
        if (error) {
            navigate(`/app/campaigns/${campaignId}/posts/${requireNotNull(error.response?.data.draft_id, "Draft id must be provided in the error response.")}`, { replace: true });
        }
    }, [error, navigate, campaignId])

    const [texts, { store: storeTextInitial, update: updateTextInitial, destroy: destroyTextInitial, ...textActions }] = usePostTexts({
        params: useMemo(() => ({ post: postId }), [postId]),
    });

    const [media, { store: storeMediumInitial, update: updateMediumInitial, destroy: destroyMediumInitial, ...mediumActions }] = usePostMedia({
        params: useMemo(() => ({ post: postId }), [postId]),
    });

    const filteredMedia = useMemo(() => media.filter(({ id }) => isNotNull(id)), [media]);

    const storeText: ReturnList<PostText, PostText, null>['store'] = useCallback(async (...params) => {
        // Text store has its own loading state, so we don't need to increment the requests loading
        const text = await storeTextInitial(...params);
        update({
            dirty: true,
            text_count: post!.text_count + 1,
        }, 'local-only');
        return text;
    }, [storeTextInitial, update, post?.text_count]);

    const updateText: ReturnList<PostText, PostText, null>['update'] = useCallback(async (...params) => {
        setRequestsLoading((prev) => prev + 1);
        await updateTextInitial(...params);
        setRequestsLoading((prev) => prev - 1);
        update({
            dirty: true,
        }, 'local-only');
    }, [updateTextInitial, update]);

    const destroyText: ReturnList<PostText, PostText, null>['destroy'] = useCallback(async (...params) => {
        setRequestsLoading((prev) => prev + 1);
        await destroyTextInitial(...params);
        setRequestsLoading((prev) => prev - 1);
        update({
            dirty: true,
            text_count: post!.text_count - 1,
        }, 'local-only');
    }, [destroyTextInitial, update, post?.text_count]);

    const storeMedium: ReturnList<PostMedium, PostMedium, null>['store'] = useCallback(async (...params) => {
        setRequestsLoading((prev) => prev + 1);
        const medium = await storeMediumInitial(...params);
        setRequestsLoading((prev) => prev - 1);
        update({
            dirty: true,
            media_count: post!.media_count + 1,
        }, 'local-only');
        return medium;
    }, [storeMediumInitial, update, post?.media_count]);

    const updateMedium: ReturnList<PostMedium, PostMedium, null>['update'] = useCallback(async (...params) => {
        setRequestsLoading((prev) => prev + 1);
        await updateMediumInitial(...params);
        setRequestsLoading((prev) => prev - 1);
        update({ dirty: true }, 'local-only');
    }, [updateMediumInitial, update]);

    const destroyMedium: ReturnList<PostMedium, PostMedium, null>['destroy'] = useCallback(async (...params) => {
        setRequestsLoading((prev) => prev + 1);
        await destroyMediumInitial(...params);
        setRequestsLoading((prev) => prev - 1);
        update({
            dirty: true,
            media_count: post!.media_count - 1,
        }, 'local-only');
    }, [destroyMediumInitial, update]);

    const textLimitReached = useMemo(() => {
        const plan = campaignOwner?.subscription_tier ? plans[campaignOwner.subscription_tier] : null;
        return post && plan ? post.text_count >= plan.texts_per_post : true;
    }, [post?.text_count, campaignOwner?.subscription_tier]);

    const mediaLimitReached = useMemo(() => {
        const plan = campaignOwner?.subscription_tier ? plans[campaignOwner.subscription_tier] : null;
        return post && plan ? post.media_count >= plan.images_per_post : true;
    }, [post?.media_count, campaignOwner?.subscription_tier]);

    const [drawer, setDrawer] = useState<"settings" | "preview">("settings");

    const [oneOfs, oneOfCounts] = useMemo(() => {
        const oneOfCounts: { [key: string]: number } = {};
        const oneOfs: { [key: string]: string } = {};

        for (const { group, settings } of postSettings) {
            for (const { oneOf, name } of settings) {
                if (oneOf) {
                    oneOfCounts[oneOf] ??= 0;
                    if((post?.settings[group] as any)?.[name] as boolean) 
                        oneOfCounts[oneOf]++;
                    oneOfs[`${group}.${name}`] = oneOf;
                }
            }
        }

        return [oneOfs, oneOfCounts];
    }, [post?.settings]);

    const handleSettingsChange = async (group: keyof PostSettingsType, name: PostSettingsKeys, value: boolean | string) => {
        if (!value && oneOfs[`${group}.${name}`] && oneOfCounts[oneOfs[`${group}.${name}`]] < 2) {
            return;
        }
        setRequestsLoading((prev) => prev + 1);
        await update({
            settings: {
                ...post?.settings,
                [group]: {
                    ...post?.settings[group],
                    [name]: value,
                }
            }
        }, 'immediate');
        update({
            dirty: true,
        }, 'local-only');
        setRequestsLoading((prev) => prev - 1);
    }

    const handleRepostChange = async (value: boolean) => {
        setRequestsLoading((prev) => prev + 1);
        await update({ should_repost: value }, 'immediate');
        setRequestsLoading((prev) => prev - 1);
    }

    const handlePostNow = () => {
        withPopup("default", t("posts.postNow.button"), t("posts.postNow.title"), t("posts.postNow.text"), async () => {
            await axios.post(route("campaigns.posts.direct-post", {
                campaign: campaignId,
                post: postId
            }));
            toast({
                type: "success",
                title: t("posts.postNow.toast.title"),
                text: t("posts.postNow.toast.text")
            });
        });
    }

    const handleDiscardDraft = () => {
        withPopup("warning", t("posts.discardDraft.button"), t("posts.discardDraft.title"), t("posts.discardDraft.text"), async () => {
            await axios.post(route("campaigns.posts.discard-draft", {
                campaign: campaignId,
                post: postId
            }));
            await Promise.all([
                refresh(),
                textActions.refresh(),
                mediumActions.refresh(),
            ]);
            toast({
                type: "success",
                title: t("posts.discardDraft.toast.title"),
            });
        });
    }

    const handleDeletePost = () => {
        withPopup("warning", t("posts.deletePost.button"), t("posts.deletePost.title"), t("posts.deletePost.text"), async () => {
            await destroy();
            toast({
                type: "success",
                title: t("posts.deletePost.toast.title"),
            });
            navigate(`/app/campaigns/${campaignId}/posts`, { replace: true });
        });
    }

    const textsColumn = useMemo(() => (
        <TextsColumn
            texts={[texts, { store: storeText, update: updateText, destroy: destroyText, ...textActions }]}
            textCount={post?.text_count}
        />
    ), [texts, storeText, updateText, destroyText, textActions, post?.text_count]);

    const mediaCarousel = useMemo(() => (
        <MediaCarousel
            media={[media, {
                store: storeMedium,
                update: updateMedium,
                destroy: destroyMedium,
                ...mediumActions
            }]}
            mediaCount={post?.media_count}
            postSettings={post?.settings}
        />
    ), [media, storeMedium, updateMedium, destroyMedium, mediumActions, post?.media_count]);

    return (
        <PostParamsProvider value={{
            textStoreLoading: [textStoreLoading, setTextStoreLoading],
            screenSize,
            textLimitReached,
            mediaLimitReached,
        }}>
            <Column className="post-page" flex={1}>
                <Header
                    autosaveLoading={requestsLoading > 0 || textStoreLoading}
                    onPostNow={handlePostNow}
                    onDiscardDraft={handleDiscardDraft}
                    onDeletePost={handleDeletePost}
                    onPublish={async () => await update({ publish: true })}
                />
                {(() => {
                    switch (screenSize) {
                        case "mobile":
                            return (
                                <Column padding="30px 16px" flex={1} style={{ overflowY: "auto" }} gap="40px">
                                    {textsColumn}
                                    {mediaCarousel}
                                    <PostSettings settings={post?.settings} shouldRepost={post?.should_repost} onSettingsChange={handleSettingsChange} onRepostChange={handleRepostChange} />
                                    <Preview postId={Number(postId)} media={filteredMedia} texts={texts} />
                                </Column>
                            )
                        case "desktop":
                            return (
                                <Column padding="40px 50px" flex={1} style={{ overflowY: "auto" }} gap="30px">
                                    <Row gap="50px">
                                        {textsColumn}
                                        <Drawer<"settings" | "preview"> selected={drawer} onChangeSelected={setDrawer} items={[{
                                            name: "settings",
                                            title: t("posts.create.drawer.settings"),
                                            children: <PostSettings settings={post?.settings} shouldRepost={post?.should_repost} onSettingsChange={handleSettingsChange} onRepostChange={handleRepostChange} hideTitle />
                                        }, {
                                            name: "preview",
                                            title: t("postPreview"),
                                            children: <Preview postId={Number(postId)} media={filteredMedia} texts={texts} hideTitle />
                                        }]} />
                                    </Row>
                                    {mediaCarousel}
                                </Column>
                            )
                        case "wide-desktop":
                            return (
                                <Column padding="40px 50px" flex={1} style={{ overflowY: "auto" }} gap="30px">
                                    <Row gap="50px" vAlign="top">
                                        {textsColumn}
                                        {mediaCarousel}
                                        <Drawer<"settings" | "preview"> selected={drawer} onChangeSelected={setDrawer} items={[{
                                            name: "settings",
                                            title: t("posts.create.drawer.settings"),
                                            children: <PostSettings settings={post?.settings} shouldRepost={post?.should_repost} onSettingsChange={handleSettingsChange} onRepostChange={handleRepostChange} hideTitle />
                                        }, {
                                            name: "preview",
                                            title: t("posts.create.drawer.postPreview"),
                                            children: <Preview postId={Number(postId)} media={filteredMedia} texts={texts} hideTitle />
                                        }]} />
                                    </Row>
                                </Column>
                            )
                    }
                })()}
            </Column>
        </PostParamsProvider >
    )
}